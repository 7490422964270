import { useNavigate } from 'react-router-dom';

import Navbar from 'components/page/Navbar';
import ContentWrap from 'components/page/ContentWrap';
import { Button } from 'primereact/button';
import { useAuth } from 'hooks/context/useAuth';
import { MenuItem } from 'primereact/menuitem';
import { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { useQuery } from '@tanstack/react-query';
import { listProjects } from 'services/ether/case-manager/projects';
import { useProjectMaybe } from 'hooks/context/project/useProject';
import { getUserDisplayName } from 'utils/user';
import { useLocalization } from 'hooks/context/useLocalization';
import useGoToSupport from 'hooks/helpers/useSupportPage';
import { useProjectSelect } from 'hooks/context/project/useProjectSelect';

const PageWithNavbar: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { signOut, redirectToUdLogin, permissions, user } = useAuth();
    const { role } = user;
    const navigate = useNavigate();
    const menuRef = useRef<Menu>(null);
    const project = useProjectMaybe();
    const [localization, { currentLanguage, switchLanguage }] =
        useLocalization();
    const goToSupport = useGoToSupport();
    const projectSelect = useProjectSelect();

    const projectsQuery = useQuery<Ether.CaseManager.Project[]>({
        queryKey: ['list-project-all'],
        queryFn: ({ signal }) =>
            listProjects({
                options: {
                    limit: 99999,
                },
                signal,
            }),
    });

    const projectsLength = projectsQuery.data?.length ?? -1;

    const navigations = [
        // ...(permissions.viewUsers
        //     ? [
        //           {
        //               label: (localization.models.user.plural),
        //               url: '/users',
        //           },
        //       ]
        //     : []),
        // ...(user.isAdmin
        //     ? [
        //           {
        //               label: (localization.models.project.plural),
        //               url: '/projects',
        //           },
        //       ]
        //     : []),
        ...(permissions.viewAuthorizations
            ? [
                  {
                      label: localization.models.authorization.plural,
                      url: '/authorizations',
                  },
              ]
            : []),
        ...(permissions.viewDocuments
            ? [
                  {
                      label: localization.models.document.plural,
                      url: '/documents',
                  },
              ]
            : []),
        ...(permissions.viewTargets
            ? [
                  {
                      label: localization.models.target.plural,
                      url: '/targets',
                  },
              ]
            : []),
        ...(permissions.viewEvidences && role !== 'authorizer-user'
            ? [
                  {
                      label: localization.models.evidence.plural,
                      url: '/evidences',
                  },
              ]
            : []),
        ...(permissions.viewBlockOrders
            ? [
                  {
                      label: localization.models.blockOrder.plural,
                      url: '/block-orders',
                  },
              ]
            : []),
        ...(permissions.viewUnblockOrders
            ? [
                  {
                      label: localization.models.unblockOrder.plural,
                      url: '/unblock-orders',
                  },
              ]
            : []),
        ...(permissions.viewOperators &&
        permissions.detailOperatorCompany &&
        user.role !== 'operator'
            ? [
                  {
                      label: localization.models.operator.plural,
                      url: '/operators',
                  },
              ]
            : []),
        ...(permissions.viewOperations
            ? [
                  {
                      label: localization.models.operation.plural,
                      url: '/operations',
                  },
              ]
            : []),
        ...(permissions.viewMonitors
            ? [
                  {
                      label: localization.models.monitoring.plural,
                      //   url: '/monitoring',
                      disabled: true,
                  },
              ]
            : []),
    ];

    const menuItems: MenuItem[] = [
        ...(projectsLength > 1
            ? [
                  {
                      id: 'changeproject',
                      icon: 'pi pi-list',
                      label: localization.components.navbar.changeProject,
                      command: () => projectSelect?.enableChangeProject(),
                  },
              ]
            : []),
        ...(permissions.accessAdminPanel
            ? [
                  {
                      id: 'adminpanel',
                      icon: 'pi pi-database',
                      label: localization.components.views.adminPanel.title,
                      command: () => {
                          navigate('/admin');
                      },
                  },
              ]
            : []),
        {
            id: 'block-checker',
            icon: 'pi pi-map-marker',
            label: localization.components.common.button.navigateBlockchecker,
            url: window.EXTERNAL_URI_BLOCKCHECKER,
        },
        {
            id: 'switch-language',
            icon: 'pi pi-globe',
            label:
                currentLanguage === 'en-US'
                    ? 'Alterar para pt-BR'
                    : 'Switch to en-US',
            command: () =>
                setTimeout(
                    () =>
                        switchLanguage(
                            currentLanguage === 'en-US' ? 'pt-BR' : 'en-US'
                        ),
                    100
                ),
        },
        {
            id: 'support',
            icon: 'pi pi-question-circle',
            label: localization.components.common.button.navigateSupport,
            command: goToSupport,
        },
        {
            id: 'switch-profile',
            icon: 'pi pi-user',
            label: localization.components.common.button.changeAccount,
            command: () => redirectToUdLogin(),
        },
        {
            id: 'signout',
            icon: 'pi pi-sign-out',
            label: localization.components.common.button.signOut,
            command: () => {
                if (signOut) signOut();
            },
        },
    ];

    const showNavigations = user.role !== 'authorizer-strict';

    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Navbar
                navigations={showNavigations ? navigations : []}
                endContent={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <div className='flex flex-col gap-1 min-w-[200px] text-right'>
                            <strong>
                                {getUserDisplayName(user.data) +
                                    (user.relatedCompany
                                        ? ` - ${user.relatedCompany?.fantasy_name}`
                                        : '')}
                            </strong>
                            {project && <span>{project.name}</span>}
                        </div>
                        {/* <Button label='Sign out' onClick={signOut} /> */}
                        <Button
                            icon='pi pi-ellipsis-h'
                            onClick={(e) => menuRef?.current?.toggle(e)}
                        />
                    </div>
                }
            />
            <ContentWrap>{children}</ContentWrap>
        </>
    );
};

export default PageWithNavbar;
