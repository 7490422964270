import LightDialog from 'components/display/LightDialog';
import SelectOrCreateOperation from 'components/models/Operation/SelectOrCreateOperation';
import { useLocalization } from 'hooks/context/useLocalization';
import useUpdateOperationOrder from 'hooks/mutations/operation/useUpdateOperationOrder';
import { Button } from 'primereact/button';
import { useCallback, useMemo, useState } from 'react';
import { updateUserDefaultOperation } from 'services/ether/case-manager/operation';
import { UpdateOperationOrderEP } from 'services/ether/case-manager/operation/types';

type PartialData = Omit<UpdateOperationOrderEP.Data, 'operation_id'>;

const useUpdateOperationOrderDialog = (): {
    dialog: JSX.Element;
    show: (params: {
        data: PartialData;
        startingOperationId: string | null;
    }) => void;
} => {
    const [localization] = useLocalization();

    const [visible, setVisible] = useState(false);
    const [data, setData] = useState<PartialData | null>(null);
    const [operationId, setOperationId] = useState<string | null>(null);
    const [isDefault, setIsDefault] = useState(false);

    const show = useCallback(() => setVisible(true), []);
    const hide = useCallback(() => setVisible(false), []);

    const { mutateUpdateOperationOrder, isLoading } = useUpdateOperationOrder({
        onUpdate: hide,
    });

    const handleUpdateOrder = useCallback(() => {
        if (!operationId || !data) return;
        if (isDefault)
            updateUserDefaultOperation({
                operation_id: operationId,
            });
        mutateUpdateOperationOrder({
            operation_id: operationId,
            order_id: data.order_id,
            order_type: data.order_type,
        });
    }, [data, isDefault, mutateUpdateOperationOrder, operationId]);

    const dialog = useMemo(() => {
        return (
            <LightDialog
                className='w-[30%]'
                visible={visible}
                onHide={hide}
                header={
                    localization.components.models.operation.views.updateOperationOrder.title
                }
                footer={
                    <>
                        <Button
                            label={localization.components.common.button.cancel}
                            severity='danger'
                            onClick={hide}
                            disabled={isLoading}
                        />
                        <Button
                            label={localization.components.common.button.save}
                            severity='success'
                            onClick={handleUpdateOrder}
                            disabled={isLoading || !operationId}
                            loading={isLoading}
                        />
                    </>
                }
            >
                <SelectOrCreateOperation
                    operationId={operationId}
                    setOperationId={(op) => setOperationId(op)}
                    isDefaultOperation={isDefault}
                    setIsDefaultOperation={(d) => setIsDefault(d)}
                    ignoreDefaultInit
                />
            </LightDialog>
        );
    }, [
        visible,
        hide,
        operationId,
        isDefault,
        localization,
        isLoading,
        handleUpdateOrder,
    ]);

    return {
        dialog,
        show: (d) => {
            setData(d.data);
            setOperationId(d.startingOperationId);
            show();
        },
    };
};

export default useUpdateOperationOrderDialog;
