import _ from 'lodash';
import { listBase } from '../../base';
import { mapDevFilters, parseDataTableFilterMetaForAPI } from '../../utils';
import { ListEstiEP } from './types';

export const listESTI = ({ options, signal }: ListEstiEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListEstiEP.Filters.Map = {
        target_value: ['target_value', devFilters.target_value],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters);
    return listBase<Ether.CaseManager.Esti[]>({
        endpoint: '/list-ether-services-target-info',
        handleParams: (params) => parseDataTableFilterMetaForAPI(params),
        options: {
            ...options,
            filters: {
                ...filters,
                _fields: 'target_value,parsed_data',
            },
        },
        signal,
    });
};

export const getOneESTI = async ({
    target_value,
    signal,
}: ListEstiEP.Params.One) => {
    const esties = await listESTI({
        options: {
            devFilters: {
                target_value: target_value,
            },
            limit: 1,
        },
        signal,
    });
    return esties[0] ?? null;
};
