import api from 'services/ether/api';
import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
    ParseDataTableParams,
} from 'services/ether/utils';
import { Nullable } from 'primereact/ts-helpers';
import {
    DetailAuthorizationEP,
    EditAuthorizationEP,
    InsertAuthorizationEP,
    JoinAuthorization,
    ChangeAuthorizationStatus,
    ExportAuthorizationFilesEP,
    ListAuthorizationEP,
    DuplicateAuthorizationEP,
    SendToAuthoritySignEP,
    GetAuthorizationStatsEP,
    ChangeAuthorizationFlowEP,
} from './types';
import {
    fileDownloadBase,
    getApiBase,
    listBase,
    postApiBase,
} from 'services/ether/base';
import _ from 'lodash';

const parseAuthorizationFilters = (params : ParseDataTableParams) => {
    return parseDataTableFilterMetaForAPI({
        ...params,
        dateFields : ['block_orders.added_at']
    });
}

export const listAuthorization = async ({
    options,
    project_id,
    signal,
}: ListAuthorizationEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListAuthorizationEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        authorization_config_id: [
            'authorization_config_id',
            devFilters.authorization_config_id,
        ],
        authorization_flow_id: [
            'authorization_flow_id',
            devFilters.authorization_flow_id,
        ],
        status: ['status', devFilters.status],
        notification_status: [
            'block_orders.0|isnull',
            devFilters.notification_status,
        ],
        notification_date: [
            'block_orders.added_at',
            devFilters.notification_date,
        ],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters, {
        project_id,
    });
    return listBase<Ether.CaseManager.Authorization.Detailed[]>({
        endpoint: '/list-authorization',
        handleParams: parseAuthorizationFilters,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const listAuthorizationWithoutOrder = async ({
    options,
    project_id,
    signal,
}: ListAuthorizationEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListAuthorizationEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        authorization_config_id: [
            'authorization_config_id',
            devFilters.authorization_config_id,
        ],
        authorization_flow_id: [
            'authorization_flow_id',
            devFilters.authorization_flow_id,
        ],
        status: ['status', devFilters.status],
        notification_status: [
            'block_orders.0|isnull',
            devFilters.notification_status,
        ],
        notification_date: [
            'block_orders.added_at',
            devFilters.notification_date,
        ],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters, {
        project_id,
    });
    return listBase<Ether.CaseManager.Authorization.Detailed[]>({
        endpoint: '/list-authorizations-block',
        handleParams: parseAuthorizationFilters,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

const _detailAuthorization = ({
    project_id,
    options,
    endpoint,
    signal,
}: DetailAuthorizationEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailAuthorizationEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        authorization_config_id: [
            'authorization_config_id',
            devFilters.authorization_config_id,
        ],
        authorization_flow_id: [
            'authorization_flow_id',
            devFilters.authorization_flow_id,
        ],
        status: ['status', devFilters.status],
        block_order_id: ['block_orders._id|in@oid', devFilters.block_order_id],
        authority_data_status: [
            'authority_data.status',
            devFilters.authority_data_status,
        ],
        get_target_info: ['get_target_info', devFilters.get_target_info],
        additional_fields: ['additional_fields', devFilters.additional_fields],
        notification_status: [
            'block_orders.0|isnull',
            devFilters.notification_status,
        ],
        notification_date: [
            'block_orders.added_at',
            devFilters.notification_date,
        ],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters, {
        project_id,
    });
    return listBase<Ether.CaseManager.Authorization.Detailed[]>({
        endpoint: endpoint,
        handleParams: parseAuthorizationFilters,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const detailOneAuthorization = async ({
    _id,
    project_id,
    options,
    signal,
}: DetailAuthorizationEP.Params.One) => {
    const authorizations = await _detailAuthorization({
        project_id,
        endpoint: '/detail-authorization',
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                _id: _id,
                get_target_info: true,
                additional_fields: [
                    'created_by_data',
                    'authorizer_associations_data',
                    'authorizer_associations_data.users_data',
                    'authorization_configs_data',
                    'authorization_flows_data',
                    'documents_data',
                    'total_targets',
                    'total_evidences',
                    'total_documents',
                    'total_targets_rejected',
                    'total_targets_approved',
                    'total_targets_rejected_pre_reprove',
                    'total_targets_rejected_manual_reprove',
                    'total_targets_processed',
                ],
            },
        },
        signal,
    });
    return authorizations[0] ?? null;
};

export const detailManyAuthorization = async ({
    options,
    project_id,
    signal,
}: DetailAuthorizationEP.Params.Many) => {
    const authorizations = await _detailAuthorization({
        project_id,
        endpoint: '/detail-authorization',
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                additional_fields: [
                    'total_targets',
                    'total_evidences',
                    'created_by_data',
                    'authorization_configs_data',
                    'authorizer_associations_data',
                    'authorizer_associations_data.users_data',
                ],
            },
        },
        signal,
    });
    return authorizations;
};

export const detailManyAuthorizationGroupedByConfig = async ({
    options,
    project_id,
    signal,
}: DetailAuthorizationEP.Params.Many) => {
    const authorizations = await _detailAuthorization({
        project_id,
        endpoint: '/detail-authorization-group-config',
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                additional_fields: [
                    'total_targets',
                    'total_evidences',
                    'created_by_data',
                    'authorization_configs_data',
                    'authorizer_associations_data',
                    'authorizer_associations_data.users_data',
                ],
            },
        },
        signal,
    });
    return authorizations;
};

export const createAuthorization = (data: {
    project_id: string;
    name: string;
}) => {
    return new Promise<string>((resolve, reject) => {
        api.post<Ether.ApiResponse<string[]>>('/create-authorization', data)
            .then((res) => {
                const data = res.data.payload[0];
                if (!data)
                    throw new Error('create-authorization did not return data');
                resolve(data);
            })
            .catch((err) => reject(err));
    });
};

export const insertAuthorization = (data: InsertAuthorizationEP.Data) => {
    return new Promise<InsertAuthorizationEP.Result>((resolve, reject) => {
        api.post<Ether.ApiResponse<InsertAuthorizationEP.ApiResponse>>(
            '/insert-authorization',
            data
        )
            .then((res) => {
                const payload = res.data.payload;
                const data = payload[0];
                if (!data)
                    reject(
                        new Error('insert-authorization failed to return data')
                    );
                else if ('error' in data) reject(new Error(data.error));
                else if ('error' in data.authorization)
                    reject(new Error(data.authorization.error));
                else resolve(data as InsertAuthorizationEP.Result);
            })
            .catch((err) => reject(err));
    });
};

export const editAuthorization = (data: EditAuthorizationEP.Data) => {
    return new Promise<EditAuthorizationEP.Result>((resolve, reject) => {
        api.post<Ether.ApiResponse<EditAuthorizationEP.ApiResponse>>(
            '/edit-authorization',
            data
        )
            .then((res) => {
                const payload = res.data.payload;
                const data = payload[0];
                if (!data)
                    reject(
                        new Error('edit-authorization failed to return data')
                    );
                else if ('error' in data) reject(new Error(data.error));
                else if ('error' in data.authorization)
                    reject(new Error(data.authorization.error));
                else resolve(data as EditAuthorizationEP.Result);
            })
            .catch((err) => reject(err));
    });
};

export const joinAuthorization = (data: JoinAuthorization.Data) => {
    return new Promise<JoinAuthorization.Result>((resolve, reject) => {
        api.post<Ether.ApiResponse<JoinAuthorization.ApiResponse>>(
            '/join-authorization',
            data
        )
            .then((res) => {
                const payload = res.data.payload;
                const data = payload[0];
                if (!data)
                    reject(
                        new Error('join-authorization failed to return data')
                    );
                else if ('error' in data) reject(new Error(data.error));
                else resolve(data);
            })
            .catch((err) => reject(err));
    });
};

export const changeAuthorizationStatus = (
    data: ChangeAuthorizationStatus.Data
) => {
    return new Promise<ChangeAuthorizationStatus.Result>((resolve, reject) => {
        api.post<Ether.ApiResponse<ChangeAuthorizationStatus.ApiResponse>>(
            '/change-authorization-status',
            data
        )
            .then((res) => {
                const payload = res.data.payload;
                const data = payload[0];
                if (!data)
                    reject(
                        new Error(
                            'change-authorization-status failed to return data'
                        )
                    );
                else if ('error' in data && data.error != null)
                    reject(new Error(data.error));
                else resolve(data);
            })
            .catch((err) => reject(err));
    });
};

export const duplicateAuthorization = (data: DuplicateAuthorizationEP.Data) => {
    return postApiBase<DuplicateAuthorizationEP.Result>({
        endpoint: '/duplicate-authorization',
        data,
    });
};

export const sendAuthorizationToAuthority = (
    data: SendToAuthoritySignEP.Data
) => {
    return postApiBase<SendToAuthoritySignEP.Result>({
        endpoint: '/send-to-authority-sign',
        data,
    });
};

export const deleteAuthorization = (_id: string) => {
    return new Promise<void>((resolve, reject) => {
        api.delete(`/delete-authorization/${_id}`)
            .then(() => resolve())
            .catch((err) => reject(err));
    });
};

export const respondAuthorization = ({
    accepted,
    ...rest
}: {
    accepted: boolean;
} & (
    | {
          token: string;
      }
    | {
          authorizationId: string;
      }
)) => {
    const authorizationId =
        'authorizationId' in rest ? rest.authorizationId : null;
    const token = 'token' in rest ? rest.token : null;
    return new Promise<Ether.CaseManager.Authorization>((resolve, reject) => {
        api.post<
            Ether.ApiResponse<
                (
                    | {
                          error: string;
                      }
                    | Ether.CaseManager.Authorization
                )[]
            >
        >(
            '/respond-authorization',
            {
                authorization_id: authorizationId,
                accepted,
            },
            {
                params: {
                    token: token,
                },
            }
        )
            .then((res) => {
                const data = res.data.payload[0];
                if (!data)
                    throw new Error(
                        'respond-authorization did not return data'
                    );
                if ('error' in data) throw new Error(data.error);
                resolve(data);
            })
            .catch((err) => reject(err));
    });
};

export const getAuthorizationMetricsDashboard = (
    project_id: string,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<Ether.CaseManager.DashboardMetrics>(
        (resolve, reject) => {
            api.get<
                Ether.ApiResponse<Nullable<Ether.CaseManager.DashboardMetrics>>
            >('/metrics-dashboard', {
                signal: options?.signal,
                params: {
                    project_id,
                },
            })
                .then((res) => {
                    let data = res.data.payload;

                    if (!data) data = {} as Ether.CaseManager.DashboardMetrics;

                    const requiredKeys: {
                        [key in keyof Ether.CaseManager.DashboardMetrics]: key;
                    } = {
                        authorization_status_approved:
                            'authorization_status_approved',
                        authorization_status_draft:
                            'authorization_status_draft',
                        authorization_status_pending:
                            'authorization_status_pending',
                        authority_data_status_done:
                            'authority_data_status_done',
                        authority_data_status_new: 'authority_data_status_new',
                        authority_data_status_pending:
                            'authority_data_status_pending',
                        block_order_status_done: 'block_order_status_done',
                        block_order_status_draft: 'block_order_status_draft',
                        total_accepted_targets: 'total_accepted_targets',
                        total_authorization_configs:
                            'total_authorization_configs',
                        total_authorizations: 'total_authorizations',
                        total_block_orders: 'total_block_orders',
                        total_documents: 'total_documents',
                        total_official_documents: 'total_official_documents',
                        total_pirate_brands: 'total_pirate_brands',
                        total_rejected_targets: 'total_rejected_targets',
                        total_targets: 'total_targets',
                    };
                    Object.values(requiredKeys).forEach((key) => {
                        if (!data) return;
                        const dataField = data[key];
                        data[key] = dataField ?? 0;
                    });
                    resolve(data);
                })
                .catch((err) => reject(err));
        }
    );
};

export const exportAuthorizationFiles = (
    options: ExportAuthorizationFilesEP.Options & {
        params: ExportAuthorizationFilesEP.Params;
        signal?: AbortSignal;
    }
) => {
    return fileDownloadBase({
        params: {
            ...options.params,
            doc_date: options.params.doc_date?.toISOString(),
        },
        filename:
            options.filename ??
            `authorization_file_${options.params.authorization_id}_${
                new Date().toISOString().split('T')[0]
            }`,
        endpoint: '/export-authorization-files',
        fallbackExtension: 'zip',
        signal: options.signal,
    });
};

export const getAuthorizationStats = (options: {
    params: GetAuthorizationStatsEP.Params;
    signal?: AbortSignal;
}) => {
    return getApiBase<GetAuthorizationStatsEP.Result>({
        endpoint: '/get-authorization-stats/' + options.params.authorization_id,
        signal: options.signal,
    });
};

export const changeAuthorizationFlow = async (
    data: ChangeAuthorizationFlowEP.Data
) => {
    const payload = await postApiBase<ChangeAuthorizationFlowEP.Result>({
        endpoint: '/change-authorization-flow',
        data,
    });
    if (!payload.updated) throw new Error('change-authorization-flow not updated');
    return payload;
};