import { differenceInDays, differenceInYears } from 'date-fns';
import { CMLocalization } from 'static/language';

export const getDateISO = (date: Date): string => {
    return date.toISOString().slice(0, 10);
};

export const getDateRange = (date: Date): string => {
    const stringDate = getDateISO(date);
    return `${stringDate}T00:00:00,${stringDate}T23:59:59`;
};

export const localizeDistance = (
    date1: Date,
    date2: Date,
    localization: CMLocalization.Localization
) => {
    const locale = localization.common.age;
    const age = differenceInYears(date1, date2);
    if (age > 0)
        return age > 1
            ? locale.years.plural.replace('{value}', age.toString())
            : locale.years.singular;
    const days = differenceInDays(date1, date2);
    return age === 1
        ? locale.days.singular
        : locale.days.plural.replace('{value}', days.toString());
};
