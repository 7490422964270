import { useMutation } from '@tanstack/react-query';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { ToastMessage } from 'primereact/toast';
import {
    validateTargetsOnAuthorization,
    validateTargetsOnOrder,
} from 'services/ether/case-manager/targets';
import {
    ValidateTargetAuthorization,
    ValidateTargetBase,
    ValidateTargetOrder,
} from 'services/ether/case-manager/targets/types';
import { getErrorToast } from 'utils/errorHandler';
import { useState } from 'react';
import { Datepicker } from 'components/ethercity-primereact';
import { Button } from 'primereact/button';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import useShowTargetActionDisambiguation from 'hooks/dialogs/target/useShowTargetActionDisambiguation';
import LightDialog from 'components/display/LightDialog';

export type ValidateTargetOptions =
    | {
          authorization: Ether.CaseManager.Authorization;
          accept: boolean;
      }
    | {
          blockOrder: Ether.CaseManager.BlockOrder;
          blocked: boolean;
      }
    | {
          unblockOrder: Ether.CaseManager.UnblockOrder;
          blocked: boolean;
      };

type ValidateTargetParams = {
    targets: {
        _id: string;
        value: string;
    }[];
    checkIfAll?: boolean;
} & ValidateTargetOptions;

type ApiResponse = (
    | ValidateTargetAuthorization.SingleResult
    | ValidateTargetOrder.SingleResult
    | ValidateTargetBase.Error
)[];
type Params = ValidateTargetAuthorization.Data | ValidateTargetOrder.Data;

const useValidateTarget = ({
    token,
    onRespond,
    defaultBlockDate,
}: {
    token?: string;
    onRespond?: () => void;
    defaultBlockDate?: Date;
}): {
    validateTargets: (options: ValidateTargetParams) => void;
    isLoading: boolean;
    error: Error | null;
    dialogElement: JSX.Element;
} => {
    const [localization] = useLocalization();
    const toast = useToast();
    const [persistedParams, setPersistedParams] =
        useState<ValidateTargetParams | null>(null);
    const [blockDate, setBlockDate] = useState<Date | null>(
        defaultBlockDate ?? null
    );

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const hideDialog = () => {
        setBlockDate(null);
        setPersistedParams(null);
    };

    const handleUpdate = ({
        data,
        params,
    }: {
        data: ApiResponse;
        params: Params;
    }) => {
        const accepted =
            'accepted' in params ? params.accepted : params.blocked;
        const isAuthorization = 'accepted' in params;
        const isAll = 'all_targets' in params && params.all_targets;

        const firstEntry = data[0];

        const successTargets = data.filter(
            (t) => 'error' in t && t.error === null
        );
        const errorTargets = data.filter(
            (t) => 'error' in t && t.error != null
        );

        const totalCount =
            isAll && firstEntry && 'modified_count' in firstEntry
                ? firstEntry.modified_count
                : successTargets.length;

        const toastMessage: ToastMessage = {
            life: 5000,
        };
        if (errorTargets.length <= 0) {
            toastMessage.severity = 'success';
            toastMessage.summary = (
                isAuthorization
                    ? accepted
                        ? localization.endpoints.target.validate_target.approved
                        : localization.endpoints.target.validate_target.rejected
                    : accepted
                    ? localization.endpoints.target.validate_target.blocked
                    : localization.endpoints.target.validate_target.notBlocked
            ).replace('{count}', totalCount.toString());
        } else if (successTargets.length <= 0) {
            toastMessage.severity = 'error';
            toastMessage.summary =
                localization.endpoints.target.validate_target.failed;
        } else {
            toastMessage.severity = 'warn';
            toastMessage.summary = (
                isAuthorization
                    ? accepted
                        ? localization.endpoints.target.validate_target.approved
                        : localization.endpoints.target.validate_target.rejected
                    : accepted
                    ? localization.endpoints.target.validate_target.blocked
                    : localization.endpoints.target.validate_target.notBlocked
            ).replace('{count}', totalCount.toString());
            toastMessage.detail =
                localization.endpoints.target.validate_target.partial;
        }

        toast.show(toastMessage);
    };

    const validateTargetMutation = useMutation<ApiResponse, Error, Params>({
        mutationFn: (data) => {
            if ('authorization_id' in data)
                return validateTargetsOnAuthorization(data);
            else return validateTargetsOnOrder(data);
        },
        onSuccess: (data, params) => {
            hardRefresh(['target']);
            softRefresh([
                'block-order',
                'authorization',
                'unblock-order',
                'metrics',
            ]);
            handleUpdate({ data, params });
            if (onRespond) onRespond();
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const isLoading = validateTargetMutation.isPending;

    const showDisambiguation = useShowTargetActionDisambiguation();

    const validateTargetsWithDate = (
        params: ValidateTargetParams,
        date: Date | null
    ) => {
        let baseItems:
            | ValidateTargetOrder.BaseData
            | ValidateTargetAuthorization.BaseData;

        let positiveResponse: boolean;
        let positiveLabel =
            'authorization' in params
                ? localization.components.common.button.approve
                : localization.components.common.button.notBlock;
        let negativeLabel =
            'authorization' in params
                ? localization.components.common.button.reject
                : localization.components.common.button.block;
        if ('blockOrder' in params) {
            positiveResponse = !params.blocked;
            baseItems = {
                blocked: params.blocked,
                order_type: 'block_order',
                order_id: params.blockOrder._id,
                token: token,
                blocked_at: date,
            };
        } else if ('authorization' in params) {
            positiveResponse = params.accept;
            baseItems = {
                accepted: params.accept,
                authorization_id: params.authorization._id,
                token: token,
            };
        } else {
            positiveResponse = !params.blocked;
            baseItems = {
                blocked: params.blocked,
                order_type: 'unblock_order',
                order_id: params.unblockOrder._id,
                token: token,
                blocked_at: date,
            };
        }

        const validateCurrent = () =>
            validateTargetMutation.mutate({
                ...baseItems,
                target_ids: params.targets.map((t) => t._id),
            });
        const validateAll = () =>
            validateTargetMutation.mutate({
                ...baseItems,
                all_targets: true,
            });

        const answer = (
            positiveResponse ? positiveLabel : negativeLabel
        ).toLocaleLowerCase();
        if (params.checkIfAll)
            showDisambiguation({
                answer: answer,
                isRevert: false,
                onApproveAll: validateAll,
                onApprovePage: validateCurrent,
            });
        else validateCurrent();
    };

    const validateTargets = (params: ValidateTargetParams) => {
        if ('blocked' in params && params.blocked) {
            setPersistedParams(params);
        } else validateTargetsWithDate(params, null);
    };

    const dialogOk = !!blockDate && !!persistedParams;

    const defineTimeDialog = (
        <LightDialog
            header={
                localization.components.models.target.views.targetValidation
                    .selectBlockDatetime
            }
            visible={!!persistedParams}
            onHide={hideDialog}
            footer={
                <div className='flex flex-row gap-2'>
                    <Button
                        label={localization.components.common.button.save}
                        onClick={() => {
                            if (!dialogOk) return;
                            validateTargetsWithDate(persistedParams, blockDate);
                        }}
                        severity='success'
                        disabled={!dialogOk}
                        loading={validateTargetMutation.isPending}
                    />
                    <Button
                        label={localization.components.common.button.cancel}
                        onClick={hideDialog}
                    />
                </div>
            }
        >
            <Datepicker
                value={blockDate}
                onChange={(value) => setBlockDate(value)}
                type='datetime-local'
                required
            />
        </LightDialog>
    );

    return {
        validateTargets,
        error: validateTargetMutation.error,
        isLoading: isLoading,
        dialogElement: defineTimeDialog,
    };
};

export default useValidateTarget;
