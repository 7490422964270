import { useProject } from 'hooks/context/project/useProject';
import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailManyAuthorization } from 'services/ether/case-manager/authorizations';
import { DetailAuthorizationEP } from 'services/ether/case-manager/authorizations/types';
import { getFilterData } from 'utils/datatable';

const useDetailAuthorization = (
    params: DetailAuthorizationEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const project = useProject();
    const queryKey = ['authorization', 'detail', params];

    const interval = project.app_config?.show_authorization_interval;

    const createdAtFilter = interval ? Date.now() - interval * 1000 : undefined;

    const query = useQueryWithRefresh<
        Ether.CaseManager.Authorization.Detailed[],
        Error
    >({
        queryKey,
        queryFn: ({ signal }) =>
            detailManyAuthorization({
                ...params,
                options: {
                    ...params.options,
                    rawFilters: {
                        ...params.options?.rawFilters,
                        created_at: createdAtFilter
                            ? getFilterData('dateAfter', createdAtFilter)
                            : undefined,
                    },
                },
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailAuthorization;
