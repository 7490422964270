import { CMLocalization } from 'static/language';
import { getESTICurrentStatus, translateSafeIpReasons } from 'utils/target';

export const getTargetAnswer = ({
    target,
    authorization,
    showOperatorAnswer,
}: {
    target: Ether.CaseManager.Target.Detailed;
    showOperatorAnswer?: boolean;
    authorization?: Ether.CaseManager.Authorization;
}) => {
    if (authorization) {
        if (!target.authorizations) return null;
        const targetAuthorization = target.authorizations.find(
            (a) => a?._id === authorization._id && a
        );
        if (!targetAuthorization) return null;
        const approvals =
            authorization.status === 'draft'
                ? targetAuthorization.draft_accepted
                : authorization.status === 'pending'
                ? targetAuthorization.accepted
                : targetAuthorization.authority_accepted;
        return {
            approved: approvals?.accepted,
            date: approvals?.date_accepted,
            pre_approve: approvals?.pre_approve ?? false,
        };
    } else {
        if (showOperatorAnswer) {
            const answer = target.order_responses?.[0];
            if (!answer) return null;
            return {
                approved: answer.blocked,
                date: answer.blocked_at,
                pre_approve: false,
            };
        }
        const approval = target._user_fields?.responses_match?.[0];
        if (!approval) return null;
        return {
            approved: approval?.blocked,
            date: approval?.response_date,
            pre_approve: false,
        };
    }
};

export const verifyIfTargetCanBeValidated = ({
    userRole,
    authorization,
    target,
}: {
    userRole: Ether.CaseManager.Role | null;
    authorization: Ether.CaseManager.Authorization.Detailed;
    target: Ether.CaseManager.Target.Detailed;
}) => {
    const estiStatus = getESTICurrentStatus({
        target,
    });
    if (estiStatus === 'pending') return false;

    const authStatus = authorization.status;
    const auth = target.authorizations?.find(
        (a) => a?._id === authorization._id
    );
    if (!auth || !userRole) return false;
    if (auth.target_autospawn_status !== 'done') return false;
    if (
        (userRole === 'admin' || userRole === 'analyst') &&
        authStatus !== 'draft'
    )
        return false;
    if (userRole === 'authorizer-user' && authStatus !== 'pending')
        return false;
    if (
        userRole === 'authorizer-strict' &&
        (authStatus !== 'approved' ||
            authorization.authority_data?.status !== 'pending')
    )
        return false;
    const approvals =
        authStatus === 'pending'
            ? auth.accepted
            : authStatus === 'draft'
            ? auth.draft_accepted
            : authStatus === 'approved'
            ? auth.authority_accepted
            : null;
    return approvals?.accepted == null;
};

export const getDomainsFromTargetEnrich = (esti: Ether.CaseManager.Esti) => {
    const domainsList = esti.parsed_data?.domains_list;
    if (domainsList?.status === 'done' && domainsList.data.length > 0)
        return domainsList.data;
    return null;
};

export const getPreRejectedReasons = ({
    authorizationId,
    target,
    localization,
}: {
    authorizationId: string | undefined;
    target: Ether.CaseManager.Target.Detailed;
    localization: CMLocalization.Localization;
}): string[] => {
    if (!authorizationId) return [];

    const rejectedAuth = target.removed_authorizations?.find(
        (a) => a?._id === authorizationId
    );
    if (!rejectedAuth || !rejectedAuth.pre_reprove) return [];
    const reasons: string[] = translateSafeIpReasons(target, localization);

    return reasons;
};

export const getTargetMeta = ({
    target,
    field,
    refAuthorization,
}: {
    target: Ether.CaseManager.Target.Detailed;
    field: string;
    refAuthorization: Ether.CaseManager.Authorization | undefined;
}) => {
    const rejectedAuth = target.removed_authorizations?.find(
        (a) => a?._id === refAuthorization?._id
    );
    const targetAuth =
        rejectedAuth ??
        target.authorizations?.find((a) => a?._id === refAuthorization?._id);
    if (!targetAuth) return null;
    const strippedField = field.split('meta.')[1];
    if (!strippedField) return null;
    if (targetAuth.meta?.[strippedField] === '') return null;
    return targetAuth.meta?.[strippedField] ?? null;
};

export const getDefaultTypeOptions = (
    localization: CMLocalization.Localization
) => [
    {
        label: localization.components.models.target.badge.type.domain,
        value: 'domain',
    },
    {
        label: localization.components.models.target.badge.type.ipv4,
        value: 'ipv4',
    },
    {
        label: localization.components.models.target.badge.type.ipv6,
        value: 'ipv6',
    },
    {
        label: localization.components.models.target.badge.type.other,
        value: 'other',
    },
];
export const getSafeIpOptions = (localization: CMLocalization.Localization) => [
    {
        label: 'Go',
        value: 'true',
    },
    {
        label: 'Review',
        value: 'false',
    },
];
export const getYesNoOptions = (localization: CMLocalization.Localization) => [
    {
        label: localization.common.yes,
        value: 'true',
    },
    {
        label: localization.common.no,
        value: 'false',
    },
];
